import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  active: false,
  agency_id: 0,
  created: "",
  email: "",
  first_name: "",
  id: 0,
  invited: false,
  last_name: "",
  phone: "",
  updated: "",
  user_type: "",
  surrogate_full_name: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.active = action.payload.active;
      state.agency_id = action.payload.agency_id;
      state.created = action.payload.created;
      state.email = action.payload.email;
      state.first_name = action.payload.first_name;
      state.id = action.payload.id;
      state.invited = action.payload.invited;
      state.last_name = action.payload.last_name;
      state.phone = action.payload.phone;
      state.updated = action.payload.updated;
      state.user_type = action.payload.user_type;
    },
    resetUser: (state) => {
      state.active = initialState.active;
      state.agency_id = initialState.agency_id;
      state.created = initialState.created;
      state.email = initialState.email;
      state.first_name = initialState.first_name;
      state.id = initialState.id;
      state.invited = initialState.invited;
      state.last_name = initialState.last_name;
      state.phone = initialState.phone;
      state.updated = initialState.updated;
      state.user_type = initialState.user_type;
    },
    setSurrogateName: (state, action) => {
      state.surrogate_full_name = action.payload.surrogate_full_name;
    },
  },
});

export const { setUser, resetUser, setSurrogateName } =
  userSlice.actions;
export default userSlice.reducer;

import * as React from "react";
import { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { useRouter } from "next/navigation";

const mainListItems = [
  {
    text: "Tasks",
    icon: "tasks",
    url: "/admin-tasks",
  },
  {
    text: "Notifications",
    icon: "notifications",
    url: "",
  },
  {
    text: "Cases",
    icon: "cases",
    url: "/admin-cases",
  },
  {
    text: "Documents",
    icon: "documents",
    url: "",
  },
  {
    text: "Agencies",
    icon: "agencies",
    url: "",
  },
  {
    text: "Leads",
    icon: "leads",
    url: "",
  },
  {
    text: "Providers",
    icon: "providers",
    url: "",
  },
  {
    text: "Settings",
    icon: "settings",
    url: "",
  },
];

export default function MenuContent() {
  const router = useRouter();
  const [selectedInd, setSelectedInd] = useState(2);
  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: "space-between" }}>
      <List dense>
        {mainListItems.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            onClick={() => router.push(item.url)}
            sx={{
              display: "block",
              color: "#777C84",
              img: { color: "#777C84" },
              "& .Mui-selected": {
                background: "#EFF6FF",
                color: "#086092",
                img: { color: "#086092" },
              },
            }}
          >
            <ListItemButton
              selected={selectedInd === index}
              sx={{ borderRadius: 2 }}
              onClick={() => {
                setSelectedInd(index);
              }}
            >
              <ListItemIcon sx={{ minWidth: 36 }}>
                <img
                  src={`/icons/admin/${item.icon}${
                    selectedInd === index ? "-selected" : ""
                  }.svg`}
                  alt={item.icon}
                />
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}

"use client";
import { FaChevronDown } from "react-icons/fa6";
import { Popover, Typography, Box } from "@mui/material";
import { usePathname, useRouter } from "next/navigation";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "@/lib/hooks";

import { useAuth } from "@/context/AuthContext";

import "./styles/Header.scss";
import { resetUser } from "@/slices/userSlice";
import { resetOrderRequest } from "@/slices/formSlice";
import { clearRequestData } from "@/slices/requestForm";
import { clearSelectedService } from "@/slices/serviceSlice";
import { resetFiles } from "@/slices/uploadFile";

const Header: React.FC = () => {
  const pathname = usePathname();
  const userType = useAppSelector((state) => state.user.user_type);

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const router = useRouter();
  const { logout } = useAuth();

  const user = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    logout();
    handleClose();
    dispatch(resetUser());
    dispatch(clearRequestData());
    dispatch(resetOrderRequest());
    dispatch(resetFiles());
    dispatch(clearSelectedService());
    router.push("/signin");
  };

  const routeToDashboard = () => {
    router.push("/dashboard");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  if (pathname.startsWith("/surrogate-intake")) {
    return <></>;
  }

  return (
    <>
      {userType !== "admin_master" && (
        <header className={"app-header"}>
          <img
            onClick={routeToDashboard}
            className={"logo"}
            src={"/icons/dashboard-logo.svg"}
            alt="logo"
          />
          <div className={"user-info"}>
            <button className={"contact-btn"}>Contact Gather</button>
            {user.id !== 0 && (
              <div
                className="user-profle"
                onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                  setAnchorEl(e.currentTarget)
                }
              >
                <div className="data">
                  <img src="/icons/user-avatar.png" alt="User" />
                  <span>{user.first_name + " " + user.last_name}</span>
                </div>

                <FaChevronDown className={"arrowIcon"} />
              </div>
            )}
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              marginTop: "10px",
            }}
          >
            <Box
              sx={{
                bgcolor: "background.paper",
                border: "1px solid lightgray",
              }}
            >
              <Typography
                sx={{ py: 1, px: 2, width: "190px" }}
                onClick={() => {
                  router.push("/my-profile");
                  handleClose();
                }}
              >
                Profile
              </Typography>
              <Typography
                sx={{ py: 1, px: 2, width: "190px" }}
                onClick={() => {
                  router.push("/billing");
                  handleClose();
                }}
              >
                Billing
              </Typography>
              <Typography
                sx={{ py: 1, px: 2, width: "190px" }}
                onClick={() => {
                  router.push("/team");
                  handleClose();
                }}
              >
                Agency Team
              </Typography>
              <Typography
                sx={{ py: 1, px: 2, width: "190px" }}
                onClick={() => {
                  router.push("/agency-details");
                  handleClose();
                }}
              >
                Agency Details
              </Typography>
              <Typography
                sx={{
                  py: 1,
                  px: 2,
                  width: "190px",
                  borderTop: "1px solid lightgrey",
                }}
                onClick={handleLogOut}
              >
                Logout
              </Typography>
            </Box>
          </Popover>
        </header>
      )}
    </>
  );
};

export default Header;

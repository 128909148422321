"use client";
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import Cookies from "js-cookie";
import axios, { AxiosResponse } from "axios";
import { useRouter } from "next/navigation";
import { useAppDispatch } from "@/lib/hooks";

import api from "../lib/axios";
import { resetOrderRequest } from "@/slices/formSlice";
import { clearRequestData } from "@/slices/requestForm";
import { clearSelectedService } from "@/slices/serviceSlice";
import { resetFiles } from "@/slices/uploadFile";

interface AuthContextType {
  user: string | null;
  login: (email: string, password: string) => Promise<AxiosResponse<void>>;
  logout: () => void;
  isAuthenticated: boolean;
  refreshToken: () => Promise<void>;
}

const AUTH_TOKEN = Cookies.get("token");
const REFRESH_TOKEN = Cookies.get("refresh_token");

api.defaults.headers.common["Authorization"] = `Bearer ${AUTH_TOKEN}`;

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<string | null>(Cookies.get("token") || null);
  const dispatch = useAppDispatch();

  const login = async (email: string, password: string) => {
    const response = await api.post("api/v1/auth/login", { email, password });
    const token = response.data.data.token;
    const refreshToken = response.data.data.refresh_token;

    Cookies.set("token", token, { expires: 7 });
    Cookies.set("refresh_token", refreshToken, { expires: 7 });
    Cookies.set("email", email, { expires: 7 });
    setUser(email);

    return response;
  };

  const logout = () => {
    Cookies.remove("token");
    Cookies.remove("refresh_token");
    Cookies.remove("email");
    setUser(null);
    dispatch(clearRequestData());
    dispatch(resetOrderRequest());
    dispatch(resetFiles());
    dispatch(clearSelectedService());
  };

  const refreshToken = async () => {
    try {
      const response = await api.post("/api/v1/auth/refresh-token/get", {
        refresh_token: REFRESH_TOKEN,
      });
      const newToken = response.data.data.token;

      Cookies.set("token", newToken, { expires: 7 });
      api.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
    } catch (error) {
      console.error("Failed to refresh token:", error);
      logout();
    }
  };

  useEffect(() => {
    const interceptor = api.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          await refreshToken();
          originalRequest.headers["Authorization"] = `Bearer ${Cookies.get(
            "token"
          )}`;
          return axios(originalRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      api.interceptors.response.eject(interceptor);
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, login, logout, isAuthenticated: !!user, refreshToken }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  const router = useRouter();

  const logout = () => {
    router.push("/signin");

    Cookies.remove("token");
    Cookies.remove("refresh_token");
    Cookies.remove("email");
  };

  if (!context) {
    return { user: undefined, logout };
  }
  return context;
};

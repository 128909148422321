"use client";

import { Provider } from "react-redux";

import { store } from "@/lib/store";

const ReduxProvider = ({ children }: { children: React.ReactNode }) => (
  <Provider store={store}>{children}</Provider>
);

export default ReduxProvider;

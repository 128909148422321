import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface RequestFormState {
  first_name: string;
  last_name: string;
  otherLastName?: string;
  phone: string;
  email: string;
  primary_contact_id: string;
}

const initialState: RequestFormState = {
  first_name: "",
  last_name: "",
  otherLastName: "",
  phone: "",
  email: "",
  primary_contact_id: "",
};

const orderRequestForm = createSlice({
  name: "orderRequestForm",
  initialState,
  reducers: {
    setFirstName: (state, action: PayloadAction<string>) => {
      state.first_name = action.payload;
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.last_name = action.payload;
    },
    setOtherLastName: (state, action: PayloadAction<string>) => {
      state.otherLastName = action.payload;
    },
    setPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPrimaryContactId: (state, action: PayloadAction<string>) => {
      state.primary_contact_id = action.payload;
    },
    setRequestData: (state, action: PayloadAction<RequestFormState>) => {
      return { ...state, ...action.payload };
    },
    clearRequestData: () => {
      return initialState;
    },
  },
});

export const {
  setFirstName,
  setLastName,
  setOtherLastName,
  setPhone,
  setEmail,
  setPrimaryContactId,
  setRequestData,
  clearRequestData,
} = orderRequestForm.actions;

export default orderRequestForm.reducer;

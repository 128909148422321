"use client";

import * as React from "react";
import Avatar from "@mui/material/Avatar";
import MuiDrawer, { drawerClasses } from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MenuContent from "./MenuContent";
import OptionsMenu from "./OptionsMenu";
import { useAppSelector } from "@/lib/hooks";
import { usePathname } from "next/navigation";

const drawerWidth = 256;

export default function SideMenu() {
  const pathname = usePathname();

  const user = useAppSelector((state) => state.user);
  if (
    user?.user_type !== "admin_master" ||
    ["/signin", "/signup"].includes(pathname)
  )
    return <></>;

  if (pathname.startsWith("/surrogate-intake")) return <></>;

  return (
    <MuiDrawer
      variant="permanent"
      sx={{
        display: { xs: "none", md: "block" },
        [`& .${drawerClasses.paper}`]: {
          backgroundColor: "background.paper",
        },
        width: drawerWidth,
        flexShrink: 0,
        boxSizing: "border-box",
        mt: 10,
        [`& .${drawerClasses.paper}`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          p: 1.5,
          mt: 1,
          ml: 2,
        }}
      >
        <img src={"/icons/dashboard-logo.svg"} alt="logo" width={185} />
      </Box>
      <MenuContent />
      <Stack
        direction="row"
        sx={{
          p: 2,
          gap: 1,
          alignItems: "center",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <Avatar
          sizes="small"
          alt="Riley Carter"
          src="/static/images/avatar/7.jpg"
          sx={{ width: 36, height: 36 }}
        />
        <Box
          sx={{
            mr: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: 500,
              lineHeight: "16px",
              width: "180px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {user.first_name + " " + user.last_name}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              display: "inline-block",
              color: "text.secondary",
              width: "180px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {user.email}
          </Typography>
        </Box>
        <OptionsMenu />
      </Stack>
    </MuiDrawer>
  );
}

'use client';

import { createTheme } from '@mui/material/styles';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    dark: true;
    light: true;
  }
}

declare module '@mui/material/styles' {
  interface Theme {
  }
  interface Palette {
    dark: Palette['primary'];
    light: Palette['primary'];
  }
  interface PaletteOptions {
    dark?: PaletteOptions['primary'];
    light?: PaletteOptions['primary'];
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    colors: {
      border: {
          primary: string;
          secondary: string;
          destructive: string;
      };
      text: {
        body: string;
        warning: string;
        placeholder: string;
        subtle: string;
      };
      input: {
        background: string;
      };
      background: {
        subtle: string;
        accentBlue: string;
        destructive: string;
      };
    };
    radius: {
      md: string;
      xl: string;
      full: string;
    }
  }
}

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 500,
          padding: '4px 10px',
          textTransform: 'none',
          '&.MuiButton-outlinedLight': {
            color: '#111827', // Black text color for all variants
          },
          '&.MuiButton-containedLight': {
            backgroundColor: '#F3F4F6',
          }
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#086092',
    },
    dark: {
      main: '#111827',
      light: '#FFFFFF',
      dark: '#3D3D3D',
      contrastText: '#D1D5DB'
    },
    light: {
      main: '#D1D5DB',
      light: '#FFFFFF',
      dark: '#EAECF0',
      contrastText: '#111827'
    },
    grey: {
      200: '#EAECF0',
      300: '#D1D5DB',
    },
  },
  colors: {
    border: {
        primary: '#D1D5DB',
        secondary: '#EAECF0',
        destructive: '#DC2626'
    },
    text: {
      body: '#111827',
      warning: '#E5B852',
      placeholder: '#9CA3AF',
      subtle: '#6B7280',
    },
    input: {
      background: '#FFF',
    },
    background: {
      subtle: '#F3F4F6',
      accentBlue: '#EFF6FF',
      destructive: '#DC2626'
    }
  },
  radius: {
    md: '8px',
    xl: '12px',
    full: '9999px',
  }
});

export default theme;
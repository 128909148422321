import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ServiceSelectionState {
  selectedService: any | null;
}

const initialState: ServiceSelectionState = {
  selectedService: null,
};

const serviceSelectionSlice = createSlice({
  name: "serviceSelection",
  initialState,
  reducers: {
    setSelectedService: (state, action: PayloadAction<any>) => {
      state.selectedService = action.payload;
    },
    clearSelectedService: (state) => {
      state.selectedService = null;
    },
  },
});

export const { setSelectedService, clearSelectedService } =
  serviceSelectionSlice.actions;

export default serviceSelectionSlice.reducer;

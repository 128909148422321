import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "../slices/userSlice";
import caseReducer from "../slices/casesSlice";
import orderRequestReducer from "../slices/formSlice";
import orderRequestForm from "../slices/requestForm";
import serviceSelectionReducer from "../slices/serviceSlice";
import uploadReducer from "../slices/uploadFile";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  user: userReducer,
  cases: caseReducer,
  orderRequest: orderRequestReducer,
  orderRequestForm: orderRequestForm,
  serviceSelection: serviceSelectionReducer,
  upload: uploadReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const makeStore = () => {
  return configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
  });
};

export const store = makeStore();

export const persistor = persistStore(store);

export type AppStore = ReturnType<typeof makeStore>;

export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];

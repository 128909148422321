import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UploadState {
  files: File | File[] | null;
}

const initialState: UploadState = {
  files: null,
};

const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    setFiles: (state, action: PayloadAction<File | null | File[]>) => {
      state.files = action.payload;
    },
    resetFiles: (state) => {
      state.files = null;
    },
  },
});

export const { setFiles, resetFiles } = uploadSlice.actions;

export default uploadSlice.reducer;

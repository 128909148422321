import axios from "axios";
import Cookies from "js-cookie";

const apiClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_WEBSITE_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "access-control-allow-origin": "*",
  },
});

if (typeof window !== "undefined") {
  apiClient.interceptors.request.use((config) => {
    const token = Cookies.get("token");

    if (token) config.headers.Authorization = `Bearer ${token}`;

    return config;
  });
}

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      Cookies.remove("token");
      if (typeof window !== "undefined") {
        // window.location.href = "/signin";
      }
    }
    return Promise.reject(error);
  }
);

export default apiClient;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface OrderRequestState {
  step: number;
  requestId: number | null;
  agencyId: number | null;
  candidateName: string | null;
  candidateEmail: string | null;
  primaryContactName: string | null;
  primaryContactEmail: string | null;
  selectedProduct: any | null;
}

const initialState: OrderRequestState = {
  step: 0,
  requestId: null,
  agencyId: null,
  candidateName: null,
  candidateEmail: null,
  primaryContactName: null,
  primaryContactEmail: null,
  selectedProduct: null,
};

const orderRequestSlice = createSlice({
  name: "orderRequest",
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setRequestData: (
      state,
      action: PayloadAction<Omit<OrderRequestState, "step" | "selectedProduct">>
    ) => {
      Object.assign(state, action.payload);
    },
    setSelectedProduct: (state, action: PayloadAction<any | null>) => {
      state.selectedProduct = action.payload;
    },
    resetOrderRequest: () => initialState,
  },
});

export const {
  setStep,
  setRequestData,
  setSelectedProduct,
  resetOrderRequest,
} = orderRequestSlice.actions;
export default orderRequestSlice.reducer;

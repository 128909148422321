import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: "",
  name: "",
  isAuthenticated: false,
};

const casesSlice = createSlice({
  name: "cases",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.isAuthenticated = action.payload.isAuthenticated;
    },
  },
});

export const { setUser } = casesSlice.actions;
export default casesSlice.reducer;
